
import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import ReactTimeAgo from 'react-time-ago'
import { Heading, Paragraph, Button, Image, Footer, Text, Spinner } from 'grommet';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
};

function App() {
  const [date, setDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [chartLoading, setChartLoading] = useState(true)
  const [chartData, setChartData] = useState(null)
  const [currentlyViewing, setCurrentlyViewing] = useState("sightingsByHour")

  const getLatestSighting = async () => {
    const latestSightingRes = await fetch("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-5783e696-7834-4a1e-8062-53e5c459d3ae/sightings/latest", {
      method: "POST", headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic NDZjYWNmZjItOGE5MS00MDFiLTg5NjMtMjkwOTI3YmE5YjY4Okt2QmhqTVJ2VDJBd2w0T0ZkQXJ4WHhoeFZERWhwWURnTzhCcDVoU25PTFVKd2RCbkJuS3VqbUF1MGVJbGhvbXY="
      },
    })
    const latestSightingData = await latestSightingRes.json()

    setDate(new Date(latestSightingData.latestSighting).toLocaleString("en-US"))
  }

  const getSightingsByDay = async (e) => {
    setChartLoading(true)

    const sightingsByDayRes = await fetch("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-5783e696-7834-4a1e-8062-53e5c459d3ae/sightings/byDay", {
      method: "POST", headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic NDZjYWNmZjItOGE5MS00MDFiLTg5NjMtMjkwOTI3YmE5YjY4Okt2QmhqTVJ2VDJBd2w0T0ZkQXJ4WHhoeFZERWhwWURnTzhCcDVoU25PTFVKd2RCbkJuS3VqbUF1MGVJbGhvbXY="
      },
    })
    const sightingByDayData = await sightingsByDayRes.json()

    setChartData(
      {
        labels: sightingByDayData.sightingsByDay.map(day => new Date(day._id).toLocaleString("en-US")),
        datasets: [
          {
            label: 'Sightings By Day',
            data: sightingByDayData.sightingsByDay.map(day => day.count),
            backgroundColor: 'rgb(125, 76, 219)',
          }
        ]
      }
    )

    setCurrentlyViewing("sightingsByDay")

    setChartLoading(false)
  }

  const getSightingsByHour = async (e) => {
    setChartLoading(true)

    const sightingsByHourRes = await fetch("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-5783e696-7834-4a1e-8062-53e5c459d3ae/sightings/byHour", {
      method: "POST", headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic NDZjYWNmZjItOGE5MS00MDFiLTg5NjMtMjkwOTI3YmE5YjY4Okt2QmhqTVJ2VDJBd2w0T0ZkQXJ4WHhoeFZERWhwWURnTzhCcDVoU25PTFVKd2RCbkJuS3VqbUF1MGVJbGhvbXY="
      },
    })
    const sightingByHourData = await sightingsByHourRes.json()

    setChartData(
      {
        labels: sightingByHourData.sightingsByHour.map(hour => new Date(hour._id).toLocaleString("en-US")),
        datasets: [
          {
            label: 'Sightings By Hour',
            data: sightingByHourData.sightingsByHour.map(hour => hour.count),
            backgroundColor: 'rgb(125, 76, 219)',
          }
        ]
      }
    )

    setCurrentlyViewing("sightingsByHour")

    setChartLoading(false)
  }

  // Get the latest sighting on page load
  useEffect(() => {
    (async () => {
      await getLatestSighting()
      await getSightingsByHour()
    })()
  }, [])

  // Get the latest sighting once every minute
  useEffect(() => {
    setInterval(async () => {
      await getLatestSighting()
    }, [60000])
  }, [])

  // Create a new sighting
  const resetDate = async (e) => {
    e.preventDefault()

    setLoading(true)

    const newSightingRes = await fetch("https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-5783e696-7834-4a1e-8062-53e5c459d3ae/sightings/new", {
      method: "POST", headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic NDZjYWNmZjItOGE5MS00MDFiLTg5NjMtMjkwOTI3YmE5YjY4Okt2QmhqTVJ2VDJBd2w0T0ZkQXJ4WHhoeFZERWhwWURnTzhCcDVoU25PTFVKd2RCbkJuS3VqbUF1MGVJbGhvbXY="
      },
    })
    const newSightingData = await newSightingRes.json()

    setDate(new Date(newSightingData.newSighting).toLocaleString("en-US"))
    setLoading(false)
  }

  const renderChart = () => {
    if (chartData || !chartLoading) {
      return <Bar options={options} data={chartData} />
    }
    return <Spinner margin={{ top: "large", bottom: "large" }} size="xlarge" />
  }

  const renderDate = () => {
    if (date) {
      return date
    }
    return <Spinner margin={{ top: "large", bottom: "large" }} size="xlarge" />
  }

  return (
    <div className="App">
      <header className="App-header">
        <Heading margin={{ top: "large", bottom: "none" }} color="#06D6A0" size="xlarge">Where Is Ro?</Heading>
        <Paragraph margin={{ bottom: "xlarge" }} size="large">This question has puzzled many of the world's greatest minds for millennia. The man is truly an enigma. Sightings of Ro, dubbed "Ro sightings", are rare but not unheard of. You can use this page to track Ro sightings.</Paragraph>
        <Image margin={{ bottom: "xlarge" }} src="/logo194.jpg" className="App-logo" alt="logo" />
        <Heading margin={{ top: "small", bottom: "none" }} size="small">Ro was last seen</Heading>
        <Heading margin={{ top: "small", bottom: "none" }} color="#DA4167">{renderDate()}</Heading>
        {date && (
          <React.Fragment>
          <Paragraph margin={{ bottom: "large" }} size="large" color="#FCE762">That's <ReactTimeAgo date={date} locale="en-US" />!</Paragraph>
          <Button primary label="I JUST SAW HIM!" margin={{ top: "medium", bottom: "xlarge" }} size="large" busy={loading} onClick={resetDate} />
          </React.Fragment>
        )}

        <Heading margin={{ top: "xlarge", bottom: "medium" }} color="#06D6A0" size="large">The Stats</Heading>
        <div style={{ marginBottom: "50px" }}>
          <Button primary label="Sightings By Hour" margin={{ top: "medium", bottom: "medium", right: "medium" }} disabled={currentlyViewing === "sightingsByHour"} size="large" busy={chartLoading} onClick={getSightingsByHour} />
          <Button primary label="Sightings By Day" margin={{ top: "medium", bottom: "medium" }} size="large" disabled={currentlyViewing === "sightingsByDay"} busy={chartLoading} onClick={getSightingsByDay} />
        </div>
        <div style={{ width: "70vw" }}>
          <center>
            {renderChart()}
          </center>
        </div>
      </header>

      <Footer background="#000" pad="medium" direction="column">
        <Text>Made by making ❤️ to YOUR MOM!</Text>
        <Text size="small">Copyright ©️ The Beginning of Time - 2023 Ro-Boats Inc. All rights reserved. We will sue if necessary.</Text>
      </Footer>
    </div>
  );
}

export default App;
